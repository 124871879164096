<template>
  <div class="minbox">
    <!-- 头部导航区域 -->
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar ">
        <Navbar :itemIndex="activeIndex" />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="boxContentBg">
      <div class="banner">
        <div class="bannerTitle">
          资金管理一体化解决方案（STP）
        </div>
        <div class="seeMore">
          <div>快速高效的实现机构间资金管理</div>
          <div>通过“STP”有效的实现前、中、后台的业务处理</div>
        </div>
      </div>
    </div>
    <!-- 方案介绍 -->
    <div class="boxContentscheme">
      <div class="schemeIntroduction">
        <div class="schemeTitle currencyTitle">
          方案介绍
        </div>
        <div class="schemeDescribe currencyExplain">
          资金管理一体化是指从交易员录入交易之后就驱动一连串的前、中、后台的业务处理，包含审批流程、下单管理、成交回报、交易获取、交易证实、交易核算、结算与清算等，整个过程都在风险合规的监控与管理之下进行。我们的
          STP 解决方案还可以对接多个关联子系统，包括: (1)做市商报价
          (2)结售汇报价 (3)集中运营管理 (4)头寸风险管理 (5)资负风险管理
          (6)SWIFT报文传输管理，实现全机构资金业务统一与集中管理。
        </div>
        <div class="schemeContent ">
          <div class="shemeItem">
            <div class="shemeItemImg">
              <img
                src="../../assets/solutionImg/Straight-through-Process(1).png"
                alt=""
              />
            </div>
            <div class="shemeItemTitle">
              风控前置
            </div>
            <div class="shemeItemDescrice">
              在交易下单前进行各种市场风险与信用风险的控制，引用风控部门设定的仓位限额、Delta限额、交易限额与对手授信额度进行交易检查；根据合规引擎检查交易合规性；根据绩效管理提示交易员日中止损等。通过风险前置，能在交易前就禁止不合规的交易，避免事后发现不合规或风险事件才进行反向对冲。
            </div>
          </div>
          <div class="shemeItem">
            <div class="shemeItemImg">
              <img
                src="../../assets/solutionImg/Straight-through-Process(2).png"
                alt=""
              />
            </div>
            <div class="shemeItemTitle">
              智能信用风险管理
            </div>
            <div class="shemeItemDescrice">
              随著机器学习的技术日趋成熟，我们可为您引入对手信用风险、资产信用风险的智能分析、鉴别与预警等增值服务。根据过往交易记录取得对手信息，建立对手的行为画像，结合对手在我行的资金、资产头寸等信息，将对手的交割违约行为进行智能判断交割风险，为资金业务安全加上更强大的一把锁。
            </div>
          </div>
          <div class="shemeItem">
            <div class="shemeItemImg">
              <img
                src="../../assets/solutionImg/Straight-through-Process(3).png"
                alt=""
              />
            </div>
            <div class="shemeItemTitle">
              资金业务风险管理
            </div>
            <div class="shemeItemDescrice">
              进行多项市场风险的计量与监控，包含市值、损益、止损、久期、凸性、情景分析等，并且用参数法与模拟法计算资产VaR；根据额度与合规设置监控各类资产的信用风险，通过模型运算ECL、PD、CAV等信用风险值并作为减值依据；根据现金流预估模型以及对手画像，通过机器学习可以只能预测可能的流动性风险。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心技术  -->
    <div class="boxContentCore">
      <div class="coreTechnology">
        <div class="coreTitle currencyTitle">
          核心技术
        </div>
        <div class="coreDescribe  currencyExplain">
          20年来，我们致力于资金业务的产品研发、系统集成与专业服务，重要的核心技术包含
        </div>
        <div class="coreContent">
          <div class="coreItem">
            <div class="coreTop">
              <div class="coreImg">
                <img
                  src="../../assets/solutionImg/solution_technology_01@2x.png"
                  alt=""
                />
              </div>
              <div class="coreItem-Describe">
                报价引擎
              </div>
            </div>
            <div class="coreBottom">
              汇集多路数据源，加以清洗、过滤、混合，并控制价格风险，确保平盘损益
            </div>
          </div>
          <div class="coreItem">
            <div class="coreTop">
              <div class="coreImg">
                <img
                  src="../../assets/solutionImg/solution_technology_02@2x.png"
                  alt=""
                />
              </div>
              <div class="coreItem-Describe">
                资负优化引擎
              </div>
            </div>
            <div class="coreBottom">
              根据资产负债情况、对头寸、现金流的智能预估，能进行资产与负债之间的寻优，以达成机构战略目标
            </div>
          </div>
          <div class="coreItem">
            <div class="coreTop">
              <div class="coreImg">
                <img
                  src="../../assets/solutionImg/solution_technology_03@2x.png"
                  alt=""
                />
              </div>
              <div class="coreItem-Describe">
                定价引擎
              </div>
            </div>
            <div class="coreBottom">
              具备超过1700种金融商品的估值定价能力，尤其对汇率、利率、贵金属衍生商品提供更专业且应用广泛的定价服务
            </div>
          </div>
          <div class="coreItem">
            <div class="coreTop">
              <div class="coreImg">
                <img
                  src="../../assets/solutionImg/solution_technology_04@2x.png"
                  alt=""
                />
              </div>
              <div class="coreItem-Describe">
                接口集成
              </div>
            </div>
            <div class="coreBottom">
              具备与多种主机、支付系统、SWIFT网络等核心系统的高通透接口对接能力
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 解决方案构成 -->
    <div class="boxContentSolut">
      <div class="solutionComposition">
        <div class="solutionTitle currencyTitle">
          解决方案构成
        </div>
        <div class="solutionDescribe currencyExplain">
          <div>资金业务一体化解决方案包含以下几个模块：(1)交易准备 (2)交易执行
          (3)成交回报 (4)交易汇集 (5)风险管理 (6)结算清算
          (7)帐务核算，使得金融市场部、风险管理部以及运营管理部得以在一个系统中完成各自的业务管理。</div>
          <div>为了让整个业务顺利直通，解决方案中还包括审批流程管理、押品与保证金管理、流动性管理与监控、估值定价引擎、以及报表与商业智能分析等辅助模块。</div>
        </div>
        <div class="solutionContent">
          <div class="solutionImg">
            <img
              src="../../assets/solutionImg/solution_constitute@2x.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 关联子系统 -->
    <div class="boxContentSystem">
      <div class="associatedSubsystem ">
        <div class="systemTitle currencyTitle">
          关联子系统
        </div>
        <div class="systemDescribe currencyExplain">
          资金业务在金融机构内部不是一个单独存在的业务，需要与许多其他业务对接，或为上游业务，或为下游业务。经过20年的积累，我们对许多银行、财务公司的相关应用场景，提供了与资金业务对接的子系统。
        </div>
        <div class="systemContent">
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_01@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">代客交易子系统</div>
              <div class="systemItemDescrice">
                应用于银行总、分、客的报价、询价、交易、风控、结清算以及平盘系统
              </div>
            </div>
          </div>
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_02@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">集中运营管理子系统</div>
              <div class="systemItemDescrice">
                多时体、跨时区、多币别、多资产的结合清系
              </div>
            </div>
          </div>
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_03@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">托管业务子系统</div>
              <div class="systemItemDescrice">
                服务QFII、RQFII、CIBM等境外投资机构投资中国银行间市场的托管系统
              </div>
            </div>
          </div>
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_04@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">头寸管理子系统</div>
              <div class="systemItemDescrice">
                提供头寸计划、汇集、分析、预测与实时监测，有效防止流动性风险、提升资金利用率
              </div>
            </div>
          </div>
        </div>
        <div class="sytemItemAlone">
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_05@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">做市商业务子系统</div>
              <div class="systemItemDescrice">
                提供LP分组报价、分层带量报价、订单交易、撮合交易等新业务的做市商系统
              </div>
            </div>
          </div>
          <div class="systemItem">
            <div class="systemItemImg">
              <img
                src="../../assets/solutionImg/solution_subsystem_06@2x.png"
                alt=""
              />
            </div>
            <div class="systemItemTerm">
              <div class="systemItemTermTitle">SWIFT报送集成子系统</div>
              <div class="systemItemDescrice">
                集成了SWIFT报文接收、解析、匹配、核对、分装、发送等功能的系统
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 方案优势 -->
    <div class="boxContentAdv">
      <div class="advantagesScheme ">
        <div class="advTitle currencyTitle">
          方案优势
        </div>
        <div class="advDescribe currencyExplain">
          资金业务一体化系统带给客户的效益不仅仅是通过直通式处理降低了人力、打通了传统的业务瓶颈、提升了业务量，还在于能真正落实金融机构的合规和监管：通过风控前置，让交易员所有的交易在发起前就能确定该交易是否能被接受，降低与对手议价的成本；通过审批流程能确保该交易合法合规；全流程在线处理，还能根本降低作业风险、根本杜绝道德风险的发生。
        </div>
      </div>
    </div>
    <!-- 经典案例 -->
    <div class="boxContentCases">
      <div class="classicCases">
        <div class="casesTitle currencyTitle">
          经典案例
        </div>
        <div class="casesDescrice currencyExplain">
          资金业务一体化系统带给客户的效益不仅仅是通过直通式处理降低了人力、打通了传统的业务瓶颈、提升了业务量，还在于能真正落实金融机构的合规和监管：通过风控前置，让交易员所有的交易在发起前就能确定该交易是否能被接受，降低与对手议价的成本；通过审批流程能确保该交易合法合规；全流程在线处理，还能根本降低作业风险、根本杜绝道德风险的发生
        </div>
        <div class="casesContent">
          <div class="casesContentItem">
            <div class="casesContentItemImg">
              <img
                src="../../assets/solutionImg/solution_partner_nh@2x.png"
                alt=""
              />
            </div>
            <div class="casesContentItemTitle">
              中国农业银行
            </div>
            <div class="casesContentItemline"></div>
            <div class="casesContentItemExplain">
              我们持续服务农业银行长达20年，先后建置了农行的QFII、QDII托管系统、SWIFT报文传输系统、境内外币支付系统、债券系统、外币资金后台系统、外币清算系统、总行国际业务系统(HIBS系统）、外汇交易做市商系统。
              农业银行成功地应用我们的资金业务一体化系统，目前直通率高达99.79%，不仅资金业务在我们的平台上进行直通管理，全行的营运后台也纳入该平台的管理，后台人员从80多人简化为10多人，应用成效十分卓著。
            </div>
          </div>
          <div class="casesContentItem">
            <div class="casesContentItemImg">
              <img
                src="../../assets/solutionImg/solution_partner_jckh@2x.png"
                alt=""
              />
            </div>
            <div class="casesContentItemTitle">
              中国进出口银行
            </div>
            <div class="casesContentItemline"></div>
            <div class="casesContentItemExplain">
              我们持续服务进出口银行超过10年，我们协助进出口银行建置了资金管理系统、外汇交易中心新一代外币交易系统的做市商系统、结售汇报价系统与代客外汇买卖系统。
            </div>
          </div>
          <div class="casesContentItem">
            <div class="casesContentItemImg">
              <img
                src="../../assets/solutionImg/solution_partner_msyh@2x.png"
                alt=""
              />
            </div>
            <div class="casesContentItemTitle">
              中国民生银行
            </div>
            <div class="casesContentItemline"></div>
            <div class="casesContentItemExplain">
              我们协助民生银行建置了QFII托管银行信息交换系统，将QFII及QDII托管业务指令和信息接收、发送、转换和处理进行集中处理，并在既有系统基础上强化了权限管理、差错预警、监督核查等方面的内容，提升了民生银行风险防范的能力。
            </div>
          </div>
        </div>
        <div class="casesContents">
          <div class="casesContentItem ">
            <div class="casesContentItemImg">
              <img
                src="../../assets/solutionImg/solution_partner_yq@2x.png"
                alt=""
              />
            </div>
            <div class="casesContentItemTitle">
              中国一汽
            </div>
            <div class="casesContentItemline"></div>
            <div class="casesContentItemExplain">
              随著贸金业务的开展以及结售汇业务的开放，中国一汽引入我们的结售汇报价系统与代客外汇买卖系统，将以往的电话询价模式进化为在线报价交易，不仅减少了人为错误，降低了操作风险，还大大提高了交易员的工作效率
            </div>
          </div>
          <div class="casesContentItem ">
            <div class="casesContentItemImg">
              <img
                src="../../assets/solutionImg/solution_partner_xmyh@2x.png"
                alt=""
              />
            </div>
            <div class="casesContentItemTitle">
              厦门银行
            </div>
            <div class="casesContentItemline"></div>
            <div class="casesContentItemExplain">
              厦门银行也是我们重要的合作伙伴，在长达20年的持续合作中，我们协助厦门银行建置了许多资金相关的业务系统，其中国际结算系统以及结售汇报价系统与代客外汇买卖系统提供各类申报、管理及查询报表，为交易员管理交易及申报各类报表，提供了方便，并降低了人为操作的风险。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>

<script>
import Navbar from '../../components/public/NavBar'
import FooterAll from '../../components/public/footerAll'
export default {
   data() {
    return {
      activeIndex: ''
    }
  },
  components: {
    Navbar,
    FooterAll,
  },
  created() {
    this.activeIndex =  this.$route.query.id
  },
}
</script>
<style lang="scss" scoped>
.currency {
  .currencyTitle {
    height: 45px;
    padding-top: 48px;
    padding-bottom: 32px;
    font-size: 32px;
    font-weight: 600;
    color: #1b314e;
    line-height: 45px;
    text-align: center;

  }
  .currencyExplain {
    font-size: 14px;
    font-weight: 400;
    color: #181818; // 默认为18
    line-height: 24px;
    text-align: center;
  }
}
.minbox {
  width: 100%;
  min-width: 1180px;
  background: #fbfbfb;
  > div > div {
    width: 1180px;
    margin: 0 auto;
  }
  .boxContent {
    background: #ffffff;
    .navbar {
      height: 68px;
    }
  }
  .boxContentBg {
    width: 100%;
    background: url('../../assets/solutionImg/solution_banner.png') no-repeat
      center center;
    // background-size: 100% 100%;
    height: 320px;
    .banner {
      .bannerTitle {
        //  width: 626px;
        height: 59px;
        font-size: 42px;
        font-weight: 400;
        color: #ffffff;
        line-height: 59px;
        padding-top: 74px;

      }
      .seeMore {
        // width: 504px;
        height: 80px;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        padding-top: 34px;

        // padding-bottom: 72px;
      }
    }
  }
  .boxContentscheme {
    width: 100%;
    background: url('../../assets/solutionImg/solution_intro_bg@2x.png');
    background-size: 100% 100%;
    height: 684px;
    .schemeIntroduction {
      @extend .currency;
      .schemeTitle {
        // width: 128px;
        margin: 0 auto;
      }
      .schemeDescribe {
        width: 970px;
        height: 72px;
        margin: 0 auto;
        padding-bottom: 40px;
        color: rgba(0, 0, 0, 0.8) !important;
      }
      .schemeContent {
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        .shemeItem {
          // padding-right: 68px;
          width: 348px;
          position: relative;
          z-index: 10;
          &:hover {
            &::after {
              content: '';
              position: absolute;
              width: 380px;
              height: 438px;
              left: -16px;
              top: -16px;
              background: #fff;
              box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 9%);
              z-index: -1;
            }
          }
          .shemeItemImg {
            // width: 348px;
            height: 196px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .shemeItemTitle {
            height: 28px;
            font-size: 20px;
            font-weight: 600;
            color: #181818;
            line-height: 28px;
            padding: 16px 0 14px 0;

          }
          .shemeItemDescrice {
            // width: 348px;
            height: 132px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
          }
        }
      }
    }
  }
  .boxContentCore {
    width: 100%;
    .coreTechnology {
      @extend .currency;
      .coreTitle {
        // width: 128px;
        height: 45px;
        margin: 0 auto;

      }
      .coreDescribe {
        width: 954px;
        height: 24px;
        margin: 0 auto;
        padding-bottom: 39px;

      }
      .coreContent {
        display: flex;
        justify-content: space-between;
        .coreItem {
          width: 240px;
          position: relative;
          z-index: 10;
          &:hover {
            &::after {
              content: '';
              position: absolute;
              width: 272px;
              height: 282px;
              left: -16px;
              top: -24px;
              background: #fff;
              box-shadow: 0px 3px 20px 0px rgb(0 0 0 / 9%);
              z-index: -1;
            }
          }
          padding-bottom: 60px;
          .coreTop {
            // padding: 0 80px;
            // width: 240px;
            margin: 0 auto;
            text-align: center;
            .coreImg {
              padding: 0 80px;
              width: 80px;
              height: 80px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .coreItem-Describe {
              font-size: 20px;
              font-weight: 600;
              color: #181818;
              line-height: 28px;
              padding: 28px 0 20px 0;

            }
          }
          .coreBottom {
            width: 240px;
            height: 66px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 22px;
          }
        }
      }
    }
  }
  .boxContentSolut {
    width: 100%;
    background: url('../../assets/solutionImg/solution_constitute_bg@2x.png');
    background-size: 100% 100%;
    .solutionComposition {
      @extend .currency;
      .solutionDescribe {
        width: 988px;
        height: 72px;
        margin: 0 auto;
        font-size: 14px;
        text-align: center;
        padding-bottom: 40px;
      }
      .solutionContent {
        padding-bottom: 60px;
        .solutionImg {
          width: 100%;
          height: 596px;
          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .boxContentSystem {
    width: 100%;
    margin-bottom: -4px;
    .associatedSubsystem {
      @extend .currency;
      .systemTitle {
        // width: 160px;
        height: 45px;
        margin: 0 auto;

      }
      .systemDescribe {
        width: 954px;
        height: 48px;
        margin: 0 auto;
        padding-bottom: 40px;
      }
      .systemContent {
        display: flex;
        width: 100%;
        // height: 532px;
        flex-wrap: wrap;
        justify-content: space-between;
        .systemItem {
          padding-bottom: 80px;
        }
      }
      .sytemItemAlone {
        display: flex;
        justify-content: space-between;
        // padding-bottom: 60px;
        .systemItem {
          padding-bottom: 60px;
        }
      }
    }
  }
  .boxContentAdv {
    width: 100%;
    height: 240px;
    background: url('../../assets/solutionImg/solution_advantage_bg.png')
      no-repeat center center;
    .advantagesScheme {
      @extend .currency;
      .advTitle {
        // width: 128px;
        height: 45px;
        margin: 0 auto;
        padding-top: 45px !important;
        color: #ffffff !important;
      }
      .advDescribe {
        width: 970px;
        height: 72px;
        margin: 0 auto;
        color: rgba(255, 255, 255, 0.8) !important;
        padding-bottom: 46px;
      }
    }
  }
  .boxContentCases {
    width: 100%;
    background: url('../../assets/solutionImg/solution_example_bg@2x.png');
    background-size: 100% 100%;
    .classicCases {
      @extend .currency;
      padding-bottom: 60px;
      .casesTitle {
        // width: 128px;
        height: 45px;
        margin: 0 auto;
      }
      .casesDescrice {
        width: 970px;
        height: 72px;
        margin: 0 auto;
        color: rgb(0, 0, 0, 0.8) !important;
      }
      .casesContent {
        display: flex;
        // flex-wrap: wrap;
        padding-top: 40px;
        justify-content: space-between;
      }
      .casesContents {
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    }
  }
  .boxContentFooter {
    background: #00244f;
  }
}
.systemItem {
  display: flex;
  box-sizing: border-box;
  width: 590px;
  height: 136px;
  padding: 16px 0 16px 24px;
  margin-bottom: 48px;
  &:hover{
    background: #fff;
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
  }
  .systemItemImg {
    width: 104px;
    height: 104px;
    padding-right: 24px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .systemItemTerm {
    .systemItemTermTitle {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      color: #181818;
      line-height: 28px;
      padding-bottom: 16px;

    }
    .systemItemDescrice {
      width: 380px;
      height: 44px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
}
.casesContentItem {
  width: 322px;
  height: 524px;
  background: #ffffff;
  margin-right: 40px;
  &:hover {
    box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.09);
  }
  .casesContentItemImg {
    width: 290px;
    height: 116px;
    padding: 24px 16px 30px 16px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .casesContentItemTitle {
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    color: #181818;
    line-height: 28px;
    padding-left: 26px;
  }
  .casesContentItemline {
    width: 26px;
    height: 4px;
    background: #0f4790;
    // padding: 12px 0 14px 26px;
    margin: 12px 0 16px 26px;
  }
  .casesContentItemExplain {
    width: 270px;
    height: 264px;
    font-size: 14px;
    font-weight: 400;
    // color: #666666;
    line-height: 24px;
    padding: 0 26px 30px 26px;
    color: rgba(102, 102, 102, 0.8);
  }
}
</style>
